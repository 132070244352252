<template>
    <div class="st-main paper-main">
        <div class="st-container">
            <div class="main-l">
                <user-avatar />
                <div class="ai-review paper-score">
                    
                </div>
            </div>
            <!-- 专项训练 -->
            <div class="main-r paper-practice">                
                    <runking-list></runking-list>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "vuex";
import userAvatar from "@/views/study/UserAvatar";
import runkingList from "@/components/spacial/runking_list"

export default {

    components: {
        userAvatar,
        runkingList
    },
    data() {
        return {
        }
    },
    created() {
    },
    filters: {
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        })
    },
    methods: {
        
    }
}
</script>

<style>
</style>