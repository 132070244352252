<template>
    <el-row class="paper-list">
        <el-col class="paper-list__container">
            <div class="paper-list__container--left">
                <p class="paper-list__title">
                    试卷测试列表
                </p>
                <div class="paper-list__contain">
                    <div class="paper-list__search">
                        <el-input v-model="searchVal">
                            <el-button slot="append" icon="el-icon-search" @click="getKaoList()">搜索</el-button>
                        </el-input>
                    </div>
                    <ul class="paper-list__content">
                        <template v-for="(item,index) in testList">
                            <li :class="[current === index ? 'paper-check' : '']" :key="index" @click="handleTest(index,item)">
                                <span>{{ (testTotal-1)*testPageSize + index +1}}</span>
                                <span>{{ item.title }}</span>
                            </li>
                        </template>
                    </ul>
                    <div class="paper-list__footer">
                        <el-button size="medium" @click="testUpPage">上一页</el-button>
                        <el-button size="medium">{{this.testTotal }} / {{this.testAllPage}}</el-button>
                        <el-button size="medium" @click="testNextPage">下一页</el-button>
                    </div>
                </div>
            </div>
            <div class="paper-list__container--right">
                <p class="paper-list__title">
                    {{ currentKaoshi.title }}
                </p>
                <div class="paper-list__contain">
                    <el-button-group>
                        <el-button type="primary" @click="tab(1)">班级</el-button>
                        <el-button type="primary" @click="tab(2)">年级</el-button>
                        <el-button type="primary" @click="tab(3)">全部</el-button>
                    </el-button-group>
                    <ul class="paper-list__user">
                        <li v-if="myrunking">
                            <span :style="{fontSize: [myrunking.runk_num <= 3 ? '20px' : ''], fontStyle: [myrunking.runk_num <= 3 ? 'italic' : '']}">{{ myrunking.runk_num }}</span>
                            <div class="paper-list__user--info">
<!--                                <img :src="item.user.avatar" alt="">-->
                                <span>{{ myrunking.user.username }}</span>
                            </div>
                            <span class="paper-list__user--time">
                                    {{ myrunking.yongshi_time }}
                                </span>
                            <span>{{ myrunking.score }}分</span>
                        </li>
                        <template v-for="item in runkList">
                            <li :key="item.runk_num">
                                <span :style="{fontSize: [item.runk_num <= 3 ? '20px' : ''], fontStyle: [item.runk_num <= 3 ? 'italic' : '']}">
                                    {{ item.runk_num }}
                                </span>
                                <div class="paper-list__user--info">
                                    <img :src="item.user.avatar" alt="">
                                    <span>{{ item.user.username }}</span>
                                </div>
                                <span class="paper-list__user--time">
                                    {{ item.yongshi_time }}
                                </span>
                                <span>{{ item.score }}分</span>
                            </li>
                        </template>
                    </ul>
                    <div class="paper-list__footer">
                        <el-button size="medium" @click="runkUpPage">上一页</el-button>
                        <el-button size="medium">{{runkPage}} / {{runkAllPage}}</el-button>
                        <el-button size="medium" @click="runkNextPage">下一页</el-button>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "paper_list",
    data() {
        return {
            
            userInfo: [],            
            testList: [],
            currentKaoshi:{},         
            current: 0,      
            testTotal: 0,
            stestPage:1,
            testPageSize:10,
            searchVal: '',   

            runkPageSize:10,
            runkList: [],
            runkPage:1,            
            runkTotal:0,
            myrunking: null,
            runkType:1,
        }
    },
    created() {
        this.getKaoList(1)
        //this.getRunking()
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        testAllPage(){
            //return this.testTotal / this.testPageSize;
            return Math.ceil(this.testTotal / this.testPageSize);
        },
        runkAllPage(){
            return Math.ceil(this.runkTotal / this.runkPageSize);
        }
    },
    methods: {
        // 获取已考试卷列表
        getKaoList(isReload=0) {
            let data = {
                token: this.token,
                title: this.searchVal,
                limit:this.testPageSize,
                page:this.testPage,
            }
            console.log(data.title);
            this.axios.post('/api/exam/get_kaoshi_list', data).then(res =>{
                let paperData = res.data.data;
                this.testTotal = paperData.total;
                this.testList = paperData.list;

                
                if(this.testList.length > 0 && isReload == 1){
                    this.currentKaoshi = this.testList[0];
                    //console.log("this.currentKaoshi",this.currentKaoshi);
                    this.getRunking();
                }
            })
        },

        // 获取排行信息
        getRunking() {
            let data = {};
            data.token = this.token;
            data.set_id = this.currentKaoshi.id;
            data.page = this.runkPage;
            data.limit = this.runkPageSize;
            data.runkType = this.runkType;

            this.axios.post('/api/exam/runking', data).then(res => {
                this.runkList = res.data.data.list;
                this.userInfo = this.runkList.user;
                this.runkTotal = res.data.data.total;
                this.myrunking = res.data.data.my_runking;
            })
        },
        handleTest(index, item) {
            this.current = index;
            this.currentKaoshi = item;
            this.resetrunkData();

            this.getRunking();
        },
        resetrunkData(){
            this.runkList= [];
            this.runkPage=1;          
            this.runkTotal=0;
            this.myrunking= null;
            this.runkType=1;
        },
        testUpPage(){
            if(this.testPage>1){
                this.testPage--;
                this.getKaoList();
            }   
        },
        testNextPage(){
            if(this.testPage < this.testAllPage){
                this.testPage++;
                this.getKaoList();
            }
        },
        runkUpPage(){
            if(this.runkPage>1){
                this.runkPage--;
                this.getRunking();
            }   
        },
        runkNextPage(){
            if(this.runkPage < this.runkAllPage){
                this.runkPage++;
                this.getRunking();
            }  
        },
        tab(runkType){
            this.resetrunkData();
            
            this.runkType = runkType;
            this.getRunking();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/paper_list.scss";
</style>
